import { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import "./Login.css";
import axiosClient from "../../axios-client";
import { useStateContext } from "../context/ContextProvider.jsx";
import { Link,useNavigate } from "react-router-dom";

export default function Login() {
  const [otpCode, setOtpCode] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

  const [register, setRegister] = useState({
    phone_number: '',
  });

  const [otp, setOtp] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [otpError, setOtpError] = useState('');

  const { setCustomer, setToken } = useStateContext()
  const navigate = useNavigate();
  const onSubmitPhoneNumber = (ev) => {
    ev.preventDefault();
    setPhoneNumberError('');
    setOtpError('');

    if (!register.phone_number) {
      setPhoneNumberError('شماره موبایل الزامی است');
      return;
    }

    axiosClient.post('customer/register', register)
      .then((data) => {
        setOtpCode(true);
        setResendDisabled(true);
        setTimer(60);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setPhoneNumberError(response.data.errors?.phone_number);
        } else {
          setPhoneNumberError('کد یک بار مصرف برایتان ارسال نشد مشکلی رخ داده است');
        }
      });
  };

  const onSubmitOtp = (ev) => {
    ev.preventDefault();
    setOtpError('');

    const payload = {
      phone_number: register.phone_number,
      otp: otp,
    };

    axiosClient.post('customer/login', payload)
      .then((data) => {
        setCustomer(data.data.data.customer)
        setToken(data.data.data.token);
        window.location.href = 'myaccount'
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setOtpError(response.data.errors?.otp || 'کد یک بار مصرف اشتباه است دوباره امتحان کنید.');
        } else {
          setOtpError('کد یک بار مصرف اشتباه است دوباره امتحان کنید.');
        }
      });
  };


  const showOtpFild = (event) => {
    onSubmitPhoneNumber(event);
    event.preventDefault();
    setOtpCode(true);
    setResendDisabled(true);
    setTimer(60);
  };

  useEffect(() => {
    let interval = null;
    if (resendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendDisabled, timer]);

  return (
    <div className='bg-login-sec'>
      <Container className='login-sec'>
        <Row className='justify-content-around'>
          <Col lg={5}>
            <div className='text-center title-login d-lg-none'><Link to={'/'}>آپگریدر</Link></div>
            <div className='text-center mb-2 fs-4 d-lg-none'>مرجع تخصصی تقویت و تعمیر موتور ef7</div>
            <div className='bg-login-baner'></div>
          </Col>
          <Col lg={5}>
            <div>
              <Link to={'/'}><div className='text-center title-login d-none d-lg-block'>آپگریدر</div></Link>
              <div className='text-center mt-3 fs-4 d-none d-lg-block'>مرجع تخصصی تقویت و تعمیر موتور ef7</div>
              <div className='login-form pt-2 pb-4 text-center mt-5'>
                <div className='mb-4 mt-2'>
                  <span className='fs-5'>ورود / ثبت نام</span>
                </div>
                <form onSubmit={onSubmitPhoneNumber}>
                  <div>
                    <input
                      placeholder='09********'
                      className='login-input'
                      id='phone_number'
                      type="number"
                      value={register.phone_number}
                      onChange={ev => setRegister({ ...register, phone_number: ev.target.value })}
                    />
                    <p className='mt-2'>لطفا شماره موبایل خود را در فیلد بالا وارد کنید</p>
                    {phoneNumberError && <p className='text-danger'>{phoneNumberError}</p>}
                  </div>
                </form>

                {otpCode && (
                  <form onSubmit={onSubmitOtp}>
                    <div style={{ position: "relative" }}>
                      <input
                        placeholder='_ _ _ _ _'
                        className='login-input mb-3'
                        type="text"
                        value={otp}
                        onChange={ev => setOtp(ev.target.value)}
                      />
                      {otpError && <p className='text-danger'>{otpError}</p>}
                      <button type='submit' className='send-otp-button confirm-otp'>تایید کد</button>
                    </div>
                  </form>
                )}

                {otpCode ? (
                  <button
                    className='sendagain-otp-button'
                    onClick={showOtpFild}
                    disabled={resendDisabled}
                  >
                    {resendDisabled ? `ارسال مجدد (${timer})` : "ارسال مجدد"}
                  </button>
                ) : (
                  <button
                    type='submit'
                    className='send-otp-button'
                    onClick={onSubmitPhoneNumber}
                  >
                    ارسال کد
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
