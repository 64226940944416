import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Btns from './btns.js'; // Ensure the correct import path
import { useEffect, useMemo, useState } from "react";

import './CustomerComments.css';
import axiosClient from "../../../../axios-client";

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function CustomerComments() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState([])

    useEffect(() => {
        getCustomerComments();
    }, [])

    const getCustomerComments = () => {
        setLoading(true)
        axiosClient.get('/getCustomerComments')
            .then(({ data }) => {
                setLoading(false)
                setComment(data.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <div className='review-parent'>
            <Swiper
                loop={true}
                slidesPerView={1}
                spaceBetween={10}
                direction={'vertical'}
                pagination={{
                    clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper review-swiper"
            >
                {comment.map((comment, index) => (
                    <SwiperSlide key={index}>
                        <div className='box-review' style={{ position: "relative" }}>
                            <div className='text-start ms-2 ms-lg-0'><span className='client-name'>{comment.name}</span></div>
                            <div className=' my-2 my-lg-5 '>
                                <p style={{ textAlign: "right" }}>{comment.comment}</p>
                            </div>
                            <div className='d-flex px-2 p-lg-0' style={{ justifyContent: "space-between" }}>
                                <span className='text-muted' style={{ fontWeight: "200" }}>شغل : {comment.job}</span>
                                <span className='text-muted' style={{ fontWeight: "200" }}>خودرو : {comment.car}</span>
                            </div>
                        </div>
                        <div className='circle-box-prof d-none d-lg-block'><img src={apiKey + comment.photo} className='img-fluid img-prf' alt={comment.name} /></div>
                    </SwiperSlide>
                ))}
                <Btns />
            </Swiper>
        </div>
    );
}
