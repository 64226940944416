import { Col, Container, Row } from 'react-bootstrap'
import "./MyAccount.css"
import { Link, Outlet, Navigate } from 'react-router-dom'
import { useStateContext } from "../context/ContextProvider";
import axiosClient from "../../axios-client";

export default function MyAccount() {
    const { customer, token, setCustomer, setToken, notification } = useStateContext();

    if (!token) {
        return <Navigate to="/login" />
    }
    const onLogout = ev => {
        ev.preventDefault()

        axiosClient.post('/customer/logout')
            .then(() => {
                setCustomer({})
                setToken(null)
            })
    }

    return (
        <Container>
            <Row className='mt-4'>
                <Col className='account-sidbar my-4' xs={3}>
                    <Link to={"/myaccount"}>
                        <h5 className='interdues text-center sidbar-account-title mb-4 mb-lg-5'>حساب کاربری</h5>
                    </Link>
                    <ul className='p-0' style={{ listStyle: "none" }}>
                        <Link to={"details"}>
                            <li className='my-3 my-lg-4 sidbar-account-item'>جزئیات حساب</li>
                        </Link>
                        {/*<Link to={"orders"}>*/}
                        {/*    <li className='my-3 my-lg-4 sidbar-account-item'>سفارشات</li>*/}
                        {/*</Link>*/}
                        <Link to={"wishlist"}>
                            <li className='my-3 my-lg-4 sidbar-account-item'>علاقه مندی ها</li>
                        </Link>
                        <Link to={"address"}>
                            <li className='my-3 my-lg-4 sidbar-account-item'>آدرس</li>
                        </Link>
                        <Link>
                            <li className='my-3 my-lg-4 sidbar-account-item' onClick={onLogout}>خروج</li>
                        </Link>
                    </ul>
                </Col>
                <Col xs={9}>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    )
}
