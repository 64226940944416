import wishlist from "./../../../../Image/Wishlist.png"
import { Col, Row } from 'react-bootstrap'
import products from "./../../../../Data/productData"
import CardProductWithAccount from '../Card/CardProductWithAccount'
import "./WishList.css"

import { Navigate } from 'react-router-dom'
import { useStateContext } from "../../../context/ContextProvider";
import React, {useEffect, useState} from "react"
import axiosClient from "../../../../axios-client";
import {forEach} from "react-bootstrap/ElementChildren";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import CardProduct from "../../../Index/component/CardProduct";
import SliderNavBtns from "../../../Index/component/NewestProducts/sliderNavBtns";

export default function WishList() {

    const apiKey = process.env.REACT_APP_API_KEY;
  const { customer } = useStateContext();


    const [products_id , setProducts_id] =useState([]);
    const [products , setProducts] =useState({});



    useEffect(() => {
        getProductIds();
    }, [customer]);

    useEffect(() => {
        axiosClient.get('wish-list/getProducts',{
            params:{ids: products_id.join(',')}
        }).then(({data})=>{
            setProducts(data.products);
        })

    }, [products_id]);

    const getProductIds = ()=>{
        if(customer)
        {
            axiosClient.get(`/wish-list/getWishList/${customer}`)
                .then(({data})=>{
                    const productIdArray = data.wishItems.map(item => item.product_id);
                    setProducts_id(productIdArray);
                    console.log(productIdArray);
                })
        }
    }


    const deleteItem=(id)=>{

        axiosClient.delete(`/wish-list/remove`, {
            data: { productId: id, customerId: customer}
        })
            .then(()=>{
                getProductIds();
            })
        ;
    }
    const deleteWishList=()=>{
        axiosClient.delete(`/wish-list/deleteWishList/${customer}`)
            .then(()=>{
                getProductIds();
            })

    }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mt-4'>
        <div>
          <span className='me-lg-3 '><img src={wishlist} alt="" className="img-fluid details-prof" /></span>
          <span> علاقه مندی ها</span>
        </div>
        <button className='border-0 px-lg-3 bg-transparent' style={{color: "red"}} onClick={deleteWishList}>خالی کردن لیست </button>
      </div>
        <Row className="gy-lg-4 mt-1 my-lg-4">
            {Object.keys(products).map((productKey) => {
                const product = products[productKey];
                return (
                    <Col key={product.id} className="p-1" xs={6} lg={4}>
                        <div className='slide-wish mx-lg-3'>
                            <div className='product-card product-card2'>
                                <div className='text-center'>
                                    <button className='border-0 px-lg-3 bg-transparent' style={{color: "red"}}
                                            onClick={(ev)=>{deleteItem(product.id)}}>حذف
                                    </button>
                                </div>
                                <div><img src={apiKey + product.photo} className='img-fluid card-img' alt={product.name}/>
                                </div>
                                <div className='mb-3 text-center mt-3'>{product.name}</div>

                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                            <span className="f-links d-flex align-items-center justify-content-center">
                                <span className="me-2" style={{marginTop: "10px"}}>{product.price}</span> <img
                                src="http://localhost:3000/image/toman.svg" style={{width: "25px"}} alt=""/>
                            </span>
                                        {/*<div><span className="main-price">{props.mainPrice}</span></div>*/}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Row>

    </div>

  )
}
